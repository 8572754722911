body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding-left: 20px;
    padding-right: 20px;
    flex: 1;
    color: var(--text-color);
    background-color: var(--background-color);
}

body.nopadding {
    padding-left: 0;
    padding-right: 0;
}

body.light {
    --background-color: #F5F5F5;
    --text-color: #222222;
    --h-text-color: #333;
    --link-text-color: #1a0dab;
}

body.dark {
    --background-color: #000000;
    --text-color: #ffffff;
    --h-text-color: #999;
    --link-text-color: #0096ff;
}

h1 {
    color: #333;
}

h3 {
    margin-top: 20px;
    color: var(--h-text-color);
}

h4 {
    margin-top: 12px;
    color: var(--h-text-color);
}

ul {
    padding-left: 20px;
}

li {
    font-size: 14px;
    color: var(--h-text-color);
}

a {
    color: var(--link-text-color);
    font-weight: bold;
}

p {
    margin-top: 8px;
    /* text-indent: 2em; */
}

.social-media a {
    margin-right: 15px;
    color: #007bff;
    text-decoration: none;
}

footer {
    margin-top: 30px;
    font-size: 12px;
    color: #999;
}