.left {
    width: 50%;
    height: auto;
    padding-left: 104px;
    /* background: blue; */
}

.body {
    padding-left: 16px;
    padding-right: 16px;
}

.p {
    font-size: 14px;
}

table {
    width: 100%;
    border-collapse: collapse;
    border: 1px solid #d6d6d6;
    text-align: center;
}

table th {
    padding: 10px 0;
    font-size: 16px;
}

table td {
    padding: 10px 2px;
    border: 1px solid #d6d6d6;
    font-size: 12px;
}

.list td {
    width: 25%;
}

.menu td {
    width: 20%;
}

table .left {
    text-align: left;
    word-break: break-all;
}

table a {
    color: #2499ff;
    text-align: left;
}