.gradienty-one {
    width: 100%;
    height: 100vh;
    background: linear-gradient(to left, #5DAA80 0%, #5DAA80 20%, #F2F9F2 100%);
    display: flexbox;
    position: relative;
}

.gradienty-pur {
    width: 100%;
    height: 100vh;

    background: linear-gradient(to left, #5F6BF4 0%, #2BDFEA 50%, #F2F9F2 100%);
    display: flexbox;
    position: relative;
}

.gradienty-two {
    width: 100%;
    height: 80vh;
    background: linear-gradient(to right, #5DAA80 0%, #5DAA80 20%, #F2F9F2 100%);
    display: flexbox;
    position: relative;
}

.left-image {
    position: absolute;
    width: 600px;
    left: 25%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.right-div {
    position: absolute;
    left: 70%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.appicon {
    width: 120px;
    height: 120px;
    margin-bottom: 0;
    border-radius: 20px;
}

.title {
    font-size: 58px;
    color: white;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 12px;
}

.sub-title {
    font-size: 28px;
    color: white;
    font-weight: bold;
    margin-bottom: 0px;
    margin-top: 12px;
}

.detail {

    p {
        color: white;
        font-size: 20px;
        font-weight: bold;
    }
}


.right-image {
    position: absolute;
    width: 14%;
    background-color: red;
    left: 75%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.left-div {
    position: absolute;
    left: 25%;
    top: 50%;
    transform: translate(-50%, -50%);
}

.footer {
    width: 100%;
    height: 120px;
    background-color: #5DAA80;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        color: white;
    }
}

.footer-pur {
    width: 100%;
    height: 120px;
    background-color: #6F72F5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    p {
        color: white;
    }
}

p {
    margin-top: 0;
    margin-bottom: 0;
}

a {
    text-decoration: none
}